import React from 'react'
import { Redirect } from '@reach/router'
import { NpmScratchPage } from '@plurix/ecom-pages'

import useCmsScratchPage from '../hooks/useCmsScratchPage'

export default function Scratch() {
  const { scratchPageContent } = useCmsScratchPage()

  // redirect to 404
  if (!scratchPageContent?.isActive) {
    const params = new URLSearchParams({
      from: encodeURIComponent('/aceitar-termos-promocao'),
    })

    return <Redirect to={`/404?${params.toString()}`} noThrow />
  }

  return <NpmScratchPage />
}
